import React from 'react';
import { string } from 'prop-types';
import { LoadingBar } from 'fronds/Components';
import { useLoadingRoute } from 'react-navi';
import { appStates } from 'src/constants/appStates';
import { useSelector } from 'react-redux';

function BusyIndicator() {
    let loadingRoute = useLoadingRoute();
    const { initialized } = useSelector(state => state.user);

    const { downloadProgress, appState } = useSelector(state => ({
        downloadProgress: state.app.downloadProgress,
        appState: state.app.appState
    }));

    const busyIndicator = () => {
        return loadingRoute ||
            initialized === 'loading' ||
            appState === appStates.loading ||
            (downloadProgress >= 0 && downloadProgress < 100) ? (
            <LoadingBar downloadProgress={downloadProgress} />
        ) : null;
    };
    return busyIndicator();
}

BusyIndicator.propTypes = {
    hello: string
};

export default BusyIndicator;
