import React from 'react';
import classes from './login.css';
import { signIn } from 'src/store/actions/userAsyncActions';
import { useSelector, useDispatch } from 'react-redux';
import BusyIndicator from 'src/components/BusyIndicator';
import { appStates } from 'src/constants/appStates';
import { BugReport } from 'fronds/Components';
import { Button } from 'fronds/Controllers';

function Login() {
    const { appState } = useSelector(state => state.app);
    const dispatch = useDispatch();

    return (
        <div className={classes.root}>
            <BusyIndicator />
            <div className={classes.loginWrapper}>
                <div className={classes.title}>BARGREEN | COMMISSIONS</div>
                <div className={classes.buttonSpace}>
                    <div className={classes.buttonWrapper}>
                        <Button
                            disabled={
                                appState === appStates.loading ||
                                appState === appStates.initialized
                            }
                            onClick={() => dispatch(signIn())}
                        >
                            Sign In
                        </Button>
                    </div>
                </div>
                <div className={classes.footer}>
                    <BugReport buttonStyle={classes.bugReport} />
                </div>
            </div>
        </div>
    );
}

export default Login;
